<template>
  <!--
    穿梭树
    - 通用字段：id，name, pId
    - 调用setKey的时机, 需要data有值, 注意初始化的时候setKey 需要在 data 赋值之后;
   -->
  <div class="flex justify-center tree-list">
    <div class="w-250 auth-tree mr-10">
      <div class="auth-title">
        <div class="auth-actions">
          <el-button type="text" @click="selectAll(true)" title="选中所有下级（包含当前）">全选</el-button>
          <el-button type="text" @click="selectAll(false)" title="取消所有下级（包含当前）">全不选</el-button>
        </div>
        全部企业
        <div class="auth-nums">
          ({{checkedIds.length}}/{{data.length}})
        </div>
      </div>
      <div class="auth-content">
        <el-tree
          class="tree w-100-p"
          ref="tree"
          :data="data"
          show-checkbox
          node-key="id"
          @check="onSeleced"
          :check-strictly="true">
          <span class="custom-tree-node flex align-center justify-start" style="width:197px;" slot-scope="{ node, data }">
            <span class="custom-node-label flex-1" :title="data.name">{{ data.name }}</span>
            <span class="custom-action-btn">
              <el-button v-if="!node.isLeaf" type="text" @click="selectChilden(data, true)" title="选中所有下级（包含当前）">全选下级</el-button>
              <el-button v-if="!node.isLeaf" type="text" @click="selectChilden(data, false)" title="取消所有下级（包含当前）">取消</el-button>
            </span>
          </span>
        </el-tree>
      </div>
    </div>
    <div class="w-250 auth-tree ml-10">
      <div class="auth-title">已选企业</div>
      <div class="auth-content">
        <el-tree
          class="tree ml-10"
          node-key="companyID"
          :data="checkedNodes"
          :props="{label: 'name'}"
          :default-expand-all="true"
        >
        </el-tree>
      </div>
    </div>
  </div>
</template>

<script>
import XEUtils from 'xe-utils'
import { getComps } from '@/apis/common'
export default {
  props: {
    data: {
      type: Array
    }
  },
  data () {
    return {
      taskPromise: null,
      compList: [],
      compTree: [],
      filterText: '',
      checkedIds: [],
      checkedNodes: [],
      oldList: []
    }
  },
  created () {
    this.taskPromise = this.initComps()
  },
  methods: {
    async initComps () {
      const res = await getComps()
      if (res.code == 200) {
        const props = { key: 'id', parentKey: 'pId' }
        const compList = this.compList = res.data.rows
        this.compTree = XEUtils.toArrayTree(compList, props)
      } else {
        this.$message.error(res.msg)
      }
    },
    selectChilden (data, checked) {
      XEUtils.eachTree([data], (item) => {
        this.$refs.tree.setChecked(item, checked)
      })
      this.onSeleced()
    },
    selectAll (checked) {
      XEUtils.eachTree(this.data, (item) => {
        this.$refs.tree.setChecked(item, checked)
      })
      this.onSeleced()
    },
    onSeleced () {
      const checkedIds = this.checkedIds = this.$refs.tree.getCheckedKeys() // 全选节点
      this.checkedNodes = XEUtils.filterTree(this.data, item => checkedIds.includes(item.id))
    },
    setKeys (keys) {
      this.$refs.tree.setCheckedKeys(keys)
      this.checkedIds = keys // 全选节点
      this.onSeleced()
    },
    getKeys () {
      return this.checkedIds
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-tree-node {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .custom-node-label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &:hover .custom-action-btn {
    display: block;
  }
  .custom-action-btn {
    display: none;
  }
}
.tree-list {
  height: 450px;
}
.auth-tree {
  height: 100%;
  border: 1px solid #ebeef5;
  border-radius: 3px;
  .auth-title {
    font-weight: bold;
    position: relative;
    background: #f5f7fa;
    text-align: center;
    line-height: 32px;
  }
  .auth-actions {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    ::v-deep .el-button {
      margin-left: 3px;
    }
  }
  .auth-nums {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-weight: normal;
    font-size: 12px;
  }
  .auth-content {
    overflow-y: auto;
    height: 418px;
    padding: 5px 0;
  }
}
</style>
