/**
 * 机构类型
 * id int32
 * name String
 */
export const orgaTypes = [
  { id: 1, name: '集团企业' },
  { id: 10, name: '省级协会' },
  { id: 11, name: '市级协会' },
  { id: 12, name: '县级协会' },
  { id: 20, name: '省级政府部门' },
  { id: 21, name: '市级政府部门' },
  { id: 22, name: '县级政府部门' }
]
