<template>
  <el-dialog
    v-bind="{title:editData?'编辑机构':'新增机构',width:'430px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form ref="form" label-width="105px" :model="submitForm" :rules="rules">
      <el-form-item label="机构名称" prop="orgaName">
        <el-input class="w-250" v-model.trim="submitForm.orgaName" clearable></el-input>
      </el-form-item>
      <el-form-item label="机构类型" prop="orgaType">
        <el-select class="w-250" v-model="submitForm.orgaType" clearable>
          <el-option v-for="item in orgaTypes" :key="item.id" :value="item.id" :label="item.name" />
        </el-select>
      </el-form-item>

      <el-form-item label="所属区域" prop="regiProvinId">
        <RegionCascader ref="regionCascader" class="w-250"></RegionCascader>
      </el-form-item>

      <el-form-item label="上级机构" prop="orgaPId">
        <el-select class="w-250" v-model.trim="submitForm.orgaPId" clearable placeholder="不选表示无上级">
          <el-option v-for="item in orgas" :key="item.orgaId" :value="item.orgaId" :label="item.orgaName"></el-option>
        </el-select>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { orgaTypes } from '../constants'
import { updateOrga } from '@/apis/infoManage/organization'
import { gainRegi, getOrgas } from '@/apis/common'
import RegionCascader from '@/components/Business/RegionCascader'
export default {
  components: {
    RegionCascader
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) { this.$emit('update:visible', newValue) },
      get () { return this.visible }
    }
    // regionIds: {
    //   set () {
    //     const res = this.$refs.regionCascader.getCheckedNodes()
    //     if (res[0]) {
    //       const submitForm = this.submitForm
    //       const pathNodes = res[0].pathNodes
    //       const idProps = ['regiProvinId', 'regiCityId', 'regiCountrId']
    //       const nameProps = ['provinName', 'cityName', 'countrName']
    //       for (let i = 0, len = pathNodes.length; i < len; i++) {
    //         const { label, value } = pathNodes[i]
    //         submitForm[nameProps[i]] = label
    //         submitForm[idProps[i]] = value
    //       }
    //     }
    //   },
    //   get () {
    //     const submitForm = this.submitForm
    //     const { regiProvinId, regiCityId, regiCountrId } = submitForm
    //     return [regiProvinId, regiCityId, regiCountrId].filter(item => !!item)
    //   }
    // }
  },
  data () {
    return {
      value: [11, 88],
      orgas: [],
      loading: false,
      keyValue: 0,
      orgaTypes, // 机构类型s
      submitForm: {
        orgaId: '', // 机构id
        orgaPId: '', // 上级机构id
        orgaName: '', // 机构类型
        orgaType: '', // 角色类型
        provinName: '', // 省名称
        cityName: '', // 市名称
        countrName: '', // 区县名称
        regiProvinId: '', // 省流水号
        regiCityId: '', // 市流水号
        regiCountrId: '' // 区县流水号
      },
      rules: {
        orgaName: { required: true, trigger: 'change', message: '请输入机构名称' },
        orgaType: { required: true, trigger: 'change', message: '请选择机构类型' },
        regiCountrId: { required: true, trigger: 'change', message: '请选择所属区域' }
      },
      regionOptions: [],
      regionProps: {
        lazy: true,
        checkStrictly: true,
        lazyLoad (node, resolve) {
          const level = node.level
          if (level === 1) {
            resolve(node.children[0].label == '直辖区' ? [] : node.children)
          }

          if (level === 2 && node.data.code) {
            if (node.children && node.children.lenght > 0) {
              resolve(node.children)
            } else {
              setTimeout(() => {
                gainRegi({
                  isMapJson: false,
                  regiLevel: node.level,
                  regiCodes: [node.data.code],
                  regiDto: {}
                }).then(res => {
                  if (res.code == 200) {
                    const nodeArr = res.data[0].regiCountrs.map(val => ({
                      value: val.regiCountrId,
                      label: val.countrName,
                      code: val.countrCode,
                      leaf: true
                    })
                    )
                    resolve(nodeArr)
                  }
                })
              })
            }
          } else if (level == 3) {
            resolve([])
          }
        }
      }
    }
  },
  mounted () {
    gainRegi({
      regiLevel: 1,
      isMapJson: false,
      regiDto: {}
    }).then(res => {
      if (res.code == 200) {
        this.regionOptions = res.data.map(val => ({
          value: val.regiProvinId,
          label: val.provinName,
          code: val.provinCode,
          children: val.regiCitys.map(valCity => ({
            value: valCity.regiCityId,
            label: valCity.cityName,
            code: valCity.cityCode,
            children: []
          }))
        }))
      }
    }).catch(val => {
      console.log(val)
    })
    this.initOrgs()
  },
  methods: {
    submit () {
      const submitForm = this.submitForm
      this.setRegionsParams(submitForm)
      if (!submitForm.orgaId) submitForm.orgaId = undefined
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          updateOrga(submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('创建成功！')
              this.$emit('refresh')
              this.visibleDialog = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    setRegionsParams (submitForm) {
      const res = this.$refs.regionCascader.getCheckedNodes()
      if (res[0]) {
        const pathNodes = res[0].pathNodes
        const idProps = ['regiProvinId', 'regiCityId', 'regiCountrId']
        const nameProps = ['provinName', 'cityName', 'countrName']
        for (let i = 0; i < 3; i++) {
          const currNode = pathNodes[i] || {}
          const { label, value } = currNode
          submitForm[nameProps[i]] = label
          submitForm[idProps[i]] = value
        }
      }
    },

    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
      this.$refs.form.resetFields()
    },

    // 初始化表单
    handlerOpen () {
      const submitForm = this.submitForm
      const editData = this.editData
      if (!editData) return

      Object.keys(submitForm).forEach(key => {
        const value = editData[key]
        if (value) submitForm[key] = value
      })

      this.$nextTick(() => {
        const { regiProvinId, regiCityId, regiCountrId } = submitForm
        const regions = [regiProvinId, regiCityId, regiCountrId].filter(item => item)
        this.$refs.regionCascader.setValue(regions)
      })
    },

    initOrgs () {
      getOrgas().then((res) => {
        this.orgas = res.data.rows
      })
    }
  }
}
</script>
