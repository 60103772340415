<template>
  <PageContainer>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item label="机构名称:">
        <el-input v-model.trim="searchForm.orgaName" placeholder="机构名称" clearable></el-input>
      </el-form-item>
    </BaseSearchForm>

    <BaseTable
      id="organization"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData"
    >
      <template #buttons>
        <el-button v-auth="'信息管理_机构管理_新增'" type="primary" icon="el-icon-plus" @click="addVisible=true">新 增</el-button>
      </template>
      <vxe-column field="seq" type="seq" title="序号" width="80" align="center"></vxe-column>
      <vxe-column field="orgaName" title="机构名称"></vxe-column>
      <vxe-column field="regionName" title="所属区域"></vxe-column>
      <vxe-column field="orgaTypeName" title="机构类型"></vxe-column>
      <vxe-column field="operations" title="操作">
        <template #default={row}>
          <el-button v-auth="'信息管理_机构管理_编辑'" type="primary" @click="handlerEdit(row)">编辑</el-button>
          <el-button v-auth="'信息管理_机构管理_绑定下级企业'" type="primary" @click="handlerBindComps(row)">绑定下级企业</el-button>
          <el-button v-auth="'信息管理_机构管理_删除'" type="danger" @click="handlerDelete(row.orgaId)">删除</el-button>
        </template>
      </vxe-column>
    </BaseTable>

    <!-- 新增机构 -->
    <add :visible.sync="addVisible" :edit-data.sync="editData" @refresh="initList" />

    <!-- 绑定隶属企业 -->
    <bind-comps :visible.sync="compsVisible" :edit-data.sync="editData" @refresh="initList" />
  </PageContainer>
</template>

<script>
import { orgaTypes } from './constants'
import { getOrgaList, delOrga } from '@/apis/infoManage/organization'
import Add from './components/Add'
import BindComps from './components/BindComps'

export default {
  name: 'organization',
  data () {
    return {
      searchForm: {
        orgaName: ''
      },
      // 列表相关
      loading: false,
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 20,
        totalSize: 0
      },
      // 新增|编辑相关
      editData: null,
      addVisible: false,
      compsVisible: false
    }
  },
  created () {
    this.initList()
  },
  methods: {
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },

    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },

    initList () {
      this.loading = true
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      getOrgaList(params).then((res) => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.data.rows.map((item) => {
            if (item.provinName) {
              item.regionName = [item.provinName, item.cityName, item.countrName].filter(item => !!item).join('/')
            } else {
              item.regionName = ''
            }
            // item.regionName = item.provinName? [item.provinName, item.provinName, item.cityName].filter(item=>)`${item.provinName} / ${item.cityName} / ${item.countrName}`:'-'
            const targetType = orgaTypes.find(type => item.orgaType == type.id) || { name: '' }
            item.orgaTypeName = targetType.name
            return item
          })
          this.tablePage.totalSize = res.data.totalSize
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },

    goEditRole (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.editVisible = true
    },

    handlerEdit (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.addVisible = true
    },

    handlerBindComps (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.compsVisible = true
    },

    handlerDelete (id) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = { ids: [id] }
        delOrga(params).then((res) => {
          if (res.code == 200) {
            this.initList()
            this.$message.success('刪除成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    }

  },
  components: {
    Add,
    BindComps
  }
}
</script>

<style lang="scss" scoped>
  .system-icon-box {
    .system-icon-item {
      object-fit: contain;
      vertical-align: middle;
    }
  }
</style>
