<template>
  <el-dialog
    v-bind="{title:editData?'编辑机构':'新增机构',width:'600px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form v-loading="loading" ref="form" label-width="120px" :model="submitForm" :rules="rules">
      <el-form-item label="机构名称" prop="orgaName">
        <el-input class="w-250" v-model.trim="submitForm.orgaName" readonly></el-input>
      </el-form-item>
      <TransferTree ref="compRef" :data="comps"></TransferTree>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TransferTree from '@/components/TransferTree'
import { listComp, boundComp } from '@/apis/infoManage/organization'
import { getComps } from '@/apis/common'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) { this.$emit('update:visible', newValue) },
      get () { return this.visible }
    }
  },
  data () {
    return {
      getCompsPromise: getComps(),
      comps: [],
      compIds: [],
      loading: false,
      submitForm: {
        id: '', // 机构id
        objs: [], // 角色id
        orgaName: '' // 机构类型
      },
      rules: {}
    }
  },
  mounted () {
    this.getCompsPromise.then((res) => {
      if (res.code == 200) {
        this.comps = res.data.rows.map((item) => {
          item.name = item.compName
          item.id = item.compId
          item.pId = item.compPId
          return item
        })
      } else {
        this.comps = []
      }
    })
  },
  methods: {
    submit () {
      const submitForm = { ...this.submitForm }
      const comps = this.comps
      const checkedIds = this.$refs.compRef.getKeys()
      submitForm.objs = comps.filter(item => checkedIds.includes(item.id))
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          boundComp(submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('创建成功！')
              this.$emit('refresh')
              this.visibleDialog = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
      this.$refs.form.resetFields()
      this.$refs.compRef.setKeys([])
    },

    // 初始化表单
    handlerOpen () {
      this.loading = true
      const submitForm = this.submitForm
      const editData = this.editData
      if (!editData) return
      Object.keys(submitForm).forEach(key => {
        const value = editData[key]
        if (value) submitForm[key] = value
      })
      submitForm.id = editData.orgaId

      let checkedIds = []
      const p2 = listComp({ orgaId: editData.orgaId, pageNum: 1, pageSize: 1000 }).then((res) => {
        if (res.code == 200) {
          checkedIds = res.data.rows.map(item => item.compId)
        } else {
          this.compIds = []
        }
      }).finally(() => {
        this.loading = false
      })
      Promise.all([this.getCompsPromise, p2]).then(() => {
        this.$refs.compRef.setKeys(checkedIds)
      })
    }
  },
  components: {
    TransferTree
  }
}
</script>
