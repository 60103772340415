import http from '@/utils/http'

// 新建/修改机构
export function updateOrga (p) {
  return http.post('/mortconc/orgacs/set', p)
}

// 获取机构列表
export function getOrgaList (p) {
  return http.post('/mortconc/orgacs/list', p)
}

// 删除机构
export function delOrga (p) {
  return http.post('/mortconc/orgacs/del', p)
}

// 机构绑定的企业列表
export function listComp (p = {}) {
  return http.post('/mortconc/orgacs/listComp', p)
}

// 机构绑定企业
export function boundComp (p) {
  return http.post('/mortconc/orgacs/boundComp', p)
}
