<template>
  <!--
    1. setValue: 设置值; 数据格式：[省id, 市id, 区id]；
      注意：数组中不能有无效的id，例如：[11, 88, null] 应改为 [11, 88];
    2. getValue 获取省市区id
    3. getCheckedNodes 获取省市区节点数组
  -->
  <el-cascader
    v-model="regionIds"
    v-loading="loading"
    ref="regionCascader"
    clearable
    placeholder="请选择区域"
    :options="regionOptions"
    :props="regionProps"
    filterable>
  </el-cascader>
</template>

<script>
import { gainRegi } from '@/apis/common'
export default {
  data () {
    return {
      regionIds: [],
      loading: false,
      // 获取初始化地区
      initRegionPromise: gainRegi({
        regiLevel: 1,
        isMapJson: false,
        regiDto: {}
      }),
      regionOptions: [],
      regionProps: {
        lazy: true,
        checkStrictly: true,
        lazyLoad (node, resolve) {
          const level = node.level
          if (level === 1) {
            resolve(node.children[0].label == '直辖区' ? [] : node.children)
          }

          if (level === 2 && node.data.code) {
            if (node.children && node.children.lenght > 0) {
              resolve(node.children)
            } else {
              setTimeout(() => {
                gainRegi({
                  isMapJson: false,
                  regiLevel: node.level,
                  regiCodes: [node.data.code],
                  regiDto: {}
                }).then(res => {
                  if (res.code == 200) {
                    const nodeArr = res.data[0].regiCountrs.map(val => ({
                      value: val.regiCountrId,
                      label: val.countrName,
                      code: val.countrCode,
                      leaf: true
                    })
                    )
                    resolve(nodeArr)
                  }
                })
              })
            }
          } else if (level == 3) {
            resolve([])
          }
        }
      }
    }
  },
  created () {
    this.initRegionPromise.then(res => {
      if (res.code == 200) {
        this.regionOptions = res.data.map(val => ({
          value: val.regiProvinId,
          label: val.provinName,
          code: val.provinCode,
          children: val.regiCitys.map(valCity => ({
            value: valCity.regiCityId,
            label: valCity.cityName,
            code: valCity.cityCode,
            children: []
          }))
        }))
      }
    }).catch(val => {
      console.log(val)
    })
  },
  methods: {
    getCheckedNodes (...params) {
      return this.$refs.regionCascader.getCheckedNodes(params)
    },

    // 修复三级区域初始值回显问题，因为初始化区的时候，区域列表中没有区级的数据，所以显示异常
    async fixedShowValue (regionIds) {
      const initRegionPromise = this.initRegionPromise
      const [provinId, cityId, countrId] = regionIds || this.regionIds
      // 区域ids改变了之后，ids包含区才执行
      if (!countrId) return
      // 若在初始化区域列表完成之前调用，需要等待列表初始化完成再执行
      await initRegionPromise
      const targetProvin = this.regionOptions.find(item => item.value == provinId)
      const initCity = (targetProvin.children || []).find(item => item.value == cityId)
      // 判断市级数据是否包含该区，如果包含说明区已经存在，就不区域处理了
      if (initCity.children && initCity.children.length !== 0) return
      // 获取市级的数据拼接到区域列表中
      this.loading = true
      const res = await gainRegi({
        isMapJson: false,
        regiLevel: 2,
        regiDto: {}
      })
      let targetCity = res.data.find(item => item.regiCityId == cityId)
      targetCity = {
        value: targetCity.regiCityId,
        label: targetCity.cityName,
        code: targetCity.cityCode,
        children: targetCity.regiCountrs.map(item => {
          return {
            value: item.regiCountrId,
            label: item.countrName,
            code: item.countrCode,
            leaf: true
          }
        })
      }
      targetProvin.children.forEach((item, index) => {
        if (item.value == cityId) {
          this.$set(targetProvin.children, index, targetCity)
        }
      })
      this.loading = false
    },

    setValue (value) {
      this.regionIds = value
      this.fixedShowValue()
    },

    getValue () {
      return this.regionIds
    }
  }

}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-loading-spinner {
    margin-top: 0;
    transform: translateY(-50%);
  }
  .el-loading-spinner .circular {
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }
}
</style>
